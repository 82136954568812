// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.description {
  padding: 20px 0px 0px 0px;
}
.description .content{
  padding: 10px 0px 0px 0px;
}
.description p , .description span{
  font-size: 15px;
  font-weight: 300;
}
`, "",{"version":3,"sources":["webpack://./src/style/properties.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".description {\n  padding: 20px 0px 0px 0px;\n}\n.description .content{\n  padding: 10px 0px 0px 0px;\n}\n.description p , .description span{\n  font-size: 15px;\n  font-weight: 300;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
