// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_card {
  padding: 0px 0px 30px 0px;
}
.head_dashboard {
  padding: 0px 0px 30px 0px;
}

.head_dashboard h3 {
  color: #444;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 0px 20px 0px;
}
.head_events h3 {
  color: #444;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 0px 20px 0px;
}
.dash-sidebar {
  flex: none !important;
  width: 250px !important;
  max-width: none !important;
  min-width: none !important;
}
@media screen and (max-width: 700px) {
  .dash-sidebar {
    flex: none !important;
    width: 90px !important;
    max-width: none !important;
    min-width: none !important;
  }
}
.ant-table-wrapper .ant-table-thead > tr > th {
  background: #001529 !important;
  color: #fff !important;
}
.ant-table-wrapper .ant-table-tbody >tr >td {
  border-right: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
}`, "",{"version":3,"sources":["webpack://./src/style/dashboard.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B;AACA;EACE,qBAAqB;EACrB,uBAAuB;EACvB,0BAA0B;EAC1B,0BAA0B;AAC5B;AACA;EACE;IACE,qBAAqB;IACrB,sBAAsB;IACtB,0BAA0B;IAC1B,0BAA0B;EAC5B;AACF;AACA;EACE,8BAA8B;EAC9B,sBAAsB;AACxB;AACA;EACE,+BAA+B;EAC/B,8BAA8B;AAChC","sourcesContent":[".dashboard_card {\n  padding: 0px 0px 30px 0px;\n}\n.head_dashboard {\n  padding: 0px 0px 30px 0px;\n}\n\n.head_dashboard h3 {\n  color: #444;\n  font-size: 18px;\n  font-weight: 600;\n  padding: 5px 0px 20px 0px;\n}\n.head_events h3 {\n  color: #444;\n  font-size: 18px;\n  font-weight: 600;\n  padding: 5px 0px 20px 0px;\n}\n.dash-sidebar {\n  flex: none !important;\n  width: 250px !important;\n  max-width: none !important;\n  min-width: none !important;\n}\n@media screen and (max-width: 700px) {\n  .dash-sidebar {\n    flex: none !important;\n    width: 90px !important;\n    max-width: none !important;\n    min-width: none !important;\n  }\n}\n.ant-table-wrapper .ant-table-thead > tr > th {\n  background: #001529 !important;\n  color: #fff !important;\n}\n.ant-table-wrapper .ant-table-tbody >tr >td {\n  border-right: 1px solid #f0f0f0;\n  border-left: 1px solid #f0f0f0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
