import { useState, useMemo } from "react";
import { Button, Form, Input, notification, Select, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { post, uploadFile } from "../../services";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import ReactQuill from "react-quill";
const { Option } = Select;

const EventForm = () => {
  const logos = [
    {
      key: "FM",
      label: "FM",
      url: require("../../assets/MVL_LOGOS/FM-APPROVED.png"),
    },
    {
      key: "CCC",
      label: "CCC",
      url: require("../../assets/MVL_LOGOS/CCC.png"),
    },
    {
      key: "SGS",
      label: "SGS",
      url: require("../../assets/MVL_LOGOS/SGS.png"),
    },
    { key: "UL", label: "UL", url: require("../../assets/MVL_LOGOS/UL.png") },
    {
      key: "ETL",
      label: "ETL",
      url: require("../../assets/MVL_LOGOS/Intertek_Logo.png"),
    },
    {
      key: "ELEMENT",
      label: "ELEMENT",
      url: require("../../assets/MVL_LOGOS/element.png"),
    },
    {
      key: "UL-EU",
      label: "UL-EU",
      url: require("../../assets/MVL_LOGOS/UL-EU.png"),
    },
    {
      key: "CNS",
      label: "CNS",
      url: require("../../assets/MVL_LOGOS/CNS.png"),
    },
    {
      key: "SWRI",
      label: "SWRI",
      url: require("../../assets/MVL_LOGOS/SWRI.png"),
    },
    {
      key: "THOMAS",
      label: "THOMAS",
      url: require("../../assets/MVL_LOGOS/THOMAS BELL.png"),
    },
    {
      key: "CERTIFIRE",
      label: "CERTIFIRE",
      url: require("../../assets/MVL_LOGOS/CERTIFIRE.png"),
    },
    {
      key: "NFPA",
      label: "NFPA",
      url: require("../../assets/MVL_LOGOS/NFPA-Logo.png"),
    },
    {
      key: "CAL FIRE",
      label: "CAL FIRE",
      url: require("../../assets/MVL_LOGOS/CALFIRE.png"),
    },
    {
      key: "WIMPEY LABORATORIES",
      label: "WIMPEY LABORATORIES",
      url: require("../../assets/MVL_LOGOS/WL.png"),
    },
  ];
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [selectedLogos, setSelectedLogos] = useState([]);

  // Notification handler
  const openNotification = (message, description) => {
    notification.open({
      message,
      description,
    });
  };
  const toggleLogoSelection = (key) => {
    setSelectedLogos((prevSelected) =>
      prevSelected.includes(key)
        ? prevSelected.filter((logoKey) => logoKey !== key)
        : [...prevSelected, key]
    );
  };

  // Props for multiple image uploads
  const uploadProps = useMemo(
    () => ({
      beforeUpload: (file) => {
        const isImage = file?.type?.split("/")[0] === "image";
        const isLessThan2MB = file.size / 1024 / 1024 < 2;

        if (!isImage) {
          openNotification("Error", "Please upload image files only.");
          return false;
        }

        if (!isLessThan2MB) {
          openNotification("Error", "Image size must be less than 2 MB.");
          return false;
        }

        setFileList((prevFileList) => [...prevFileList, file]);
        return false; // Prevent automatic upload
      },
      onRemove: (file) => {
        setFileList((prevFileList) =>
          prevFileList.filter((item) => item.uid !== file.uid)
        );
      },
    }),
    [fileList]
  );

  // Props for single image upload
  const uploadProps2 = useMemo(
    () => ({
      beforeUpload: (file) => {
        const isImage = file?.type?.split("/")[0] === "image";
        if (!isImage) {
          openNotification("Error", "Please upload an image file.");
          return false;
        }
        setFileList2([file]);
        return false; // Prevent automatic upload
      },
      onRemove: () => setFileList2([]),
    }),
    []
  );

  // Function to upload files and get their URLs
  const uploadFiles = async (file) => {
    try {
      const response = await uploadFile("/getSignUrlForUpload", { file: file });
      return response?.data?.publicUrl;
    } catch (error) {
      openNotification("Error", "File upload failed.");
      return false;
    }
  };

  // Form submission handler
  const onFinish = async (values) => {
    console.log("values", values);
    openNotification(
      "Notification",
      "Please wait, image upload may take some time."
    );
    // setLoader(true);

    try {
      const files = fileList2[0];
      const uploadedFilesUrl = await uploadFiles(files);
      if (uploadedFilesUrl !== false) {
        // Construct payload
        const payload = {
          ...values,
          logo: selectedLogos,
          featuresImages: values?.featuresImages ? values?.featuresImages : "",
          images: uploadedFilesUrl,
        };
        // API call to post data
        await post(`/products`, payload);
        setLoader(false);
        navigate("/");
        openNotification("Success", "Record created successfully.");
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      openNotification("Error", error?.message || "Something went wrong.");
    }
  };

  return (
    <>
      <div className="header">
        <h3>Add FireStopping Product</h3>
      </div>
      <div className="form_container">
        <Form layout="vertical" onFinish={onFinish}>
          {/* Image */}
          <Form.Item
            name="images"
            label="Image"
            rules={[{ required: true, message: "Please upload image." }]}
          >
            <Upload
              name="logo"
              listType="picture"
              {...uploadProps2}
              fileList={fileList2}
            >
              <Button icon={<UploadOutlined />}>Upload Logo</Button>
            </Upload>
          </Form.Item>
          {/* Title Input */}
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Please enter the title." }]}
          >
            <Input />
          </Form.Item>
          {/* Category Dropdown */}
          <Form.Item
            label="Category"
            name="category"
            rules={[{ required: true, message: "Please select a category!" }]}
          >
            <Select placeholder="Select a category">
              <Option value="Fire-resistive Joint Products">
                Fire-resistive Joint Products
              </Option>
              <Option value="MEP Penetrations Firestop Products">
                MEP Penetrations Firestop Products
              </Option>
              <Option value="Fire Proof Coating Products">
                Fire Proof Coating Products
              </Option>
              <Option value="General Purpose Sealants">
                General Purpose Sealants
              </Option>
            </Select>
          </Form.Item>
          {/* Description Input */}
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please enter the description." },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          {/* Key Technical Data */}
          <Form.Item name="keyTechnicalData" label="Key Technical Data">
            <ReactQuill theme="snow" />
          </Form.Item>

          {/* tested */}
          <Form.Item name="tested" label="Tested">
            <ReactQuill theme="snow" />
          </Form.Item>

          {/* Features Applications */}
          <Form.Item name="featuresApplications" label="Features Applications">
            <ReactQuill theme="snow" />
          </Form.Item>

          {/* Featured Images */}
          <Form.Item name="featuresImages" label="Featured Images">
            <Select
              mode="multiple"
              placeholder="Select Featured Images"
              options={[
                { value: "CABLE TRAY", label: "CABLE TRAY" },
                { value: "JOINT SYSTEM", label: "JOINT SYSTEM" },
                { value: "PARTITION WALL", label: "PARTITION WALL" },
                { value: "AC DUCT", label: "AC DUCT" },
                { value: "METALLIC PIPE", label: "METALLIC PIPE" },
                { value: "ELECTRICAL BOX", label: "ELECTRICAL BOX" },
                { value: "ELECTRICAL CABLE", label: "ELECTRICAL CABLE" },
                { value: "WOOD SURFACE", label: "WOOD SURFACE" },
                { value: "WOOD SURFACE 2", label: "WOOD SURFACE 2" },
                { value: "FC9000", label: "FC9000" },
                { value: "FC9000 Wood", label: "FC9000 Wood" },
                { value: "FABRIC", label: "FABRIC" },
                { value: "STRUCTURAL STEEL", label: "STRUCTURAL STEEL" },
                { value: "PERIMETER JOINT", label: "PERIMETER JOINT" },
                { value: "HEAD OF WALL JOINT", label: "HEAD OF WALL JOINT" },
                {
                  value: "ALUMINUM CLADDING JOINT",
                  label: "ALUMINUM CLADDING JOINT",
                },
              ]}
            />
          </Form.Item>
          {/* Logo Upload */}
          <Form.Item label="Select Logos">
            <div className="logo-grid">
              {logos.map((logo) => (
                <div
                  key={logo.key}
                  className={`logo-item ${
                    selectedLogos.includes(logo.key) ? "selected" : ""
                  }`}
                  onClick={() => toggleLogoSelection(logo.key)}
                >
                  <img src={logo.url} alt={logo.label} />
                </div>
              ))}
            </div>
          </Form.Item>

          {/* Submit Buttons */}
          <Form.Item>
            <div className="form_submit">
              <Button className="back_button" onClick={() => navigate("/")}>
                Back
              </Button>
              <Button htmlType="submit" disabled={loader}>
                {loader ? "Uploading..." : "Save"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default EventForm;
