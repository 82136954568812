import { Button, Form, Input, Spin, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Logo from "../../assets/logos/logo.png";
import axios from "axios";
import cookie from "react-cookies";

const Login = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  // onChange Handler for cookie to save token
  const onChange = (newName) => {
    const oneDayInSeconds = 24 * 60 * 60; // 1 day in seconds
    cookie.save("token", newName, { path: "/", maxAge: oneDayInSeconds });
  };

  // notification handler
  const openNotification = (message, description) => {
    notification.open({
      message: message,
      description: description,
    });
  };

  // onFinish
  const onFinish = (values) => {
    setLoader(true);
    const payload = {
      email: values.email,
      password: values.password,
    };
    console.log("payload", payload);
    axios
      .post(`${process.env.REACT_APP_FRONTED_URL}/auth/login`, payload)
      .then((response) => {
        onChange(
          response?.data?.token && JSON.stringify(response?.data?.token)
        );
        setLoader(false);
        openNotification("Success", "Your are loggedIn Successfully");
        navigate("/");
      })
      .catch((error) => {
        console.log("error", error);
        setLoader(false);
        openNotification("Error", error?.response?.data?.message);
      });
  };

  return (
    <div className="login-page">
      <div className="login-box">
        <Form name="login-form" onFinish={onFinish} layout="vertical">
          <div className="logo-container">
            <img src={Logo} alt="" />
          </div>
          <Form.Item
            name="email"
            label="Email"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: "Please enter your email!" }]}
          >
            <Input placeholder="Email" style={{ fontSize: "16px" }} />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password
              placeholder="Password"
              style={{ fontSize: "16px" }}
            />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" className="login-form-button">
              {loader ? <Spin /> : "LOGIN"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
