import React, { useState } from "react";
import Logo from "../../assets/logos/logo.svg";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FireOutlined,
  UnorderedListOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, theme } from "antd";
import MenuButton from "../dropdown/Menu";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { parseJWT } from "../../utils/tokenDecode";

const { Header, Sider, Content } = Layout;

const Layout1 = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  // token for color
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  // Token
  const token = cookie.load("token");
  // function to get Tabs Props
  const getItem = (label, key, icon, children, theme) => {
    return {
      key,
      icon,
      children,
      label,
      theme,
    };
  };

  // handler to show active part
  const activeTabHandler = (acitveRoute) => {
    switch (acitveRoute) {
      case "/":
        return "2";
      case "/add-firestopping-product":
        return "2";
      case `/edit-firestopping-product/${location?.pathname?.split("/")[2]}`:
        return "2";
      // blogs
      case "/blogs":
        return "4";
      case "/add-blog":
        return "4";
      case `/edit-blog/${location?.pathname?.split("/")[2]}`:
        return "4";
      // projects
      case "/projects":
        return "3";
      case "/add-project":
        return "3";
      case `/edit-project/${location?.pathname?.split("/")[2]}`:
        return "3";
      default:
        return "1";
    }
  };

  const tabs = [
    // {
    //   id: "1",
    //   img: <UserOutlined />, // icon name
    //   route: "/",
    //   title: "Admins",
    //   accessRole: ["admin"],
    // },
    {
      id: "2",
      img: <FireOutlined />,
      route: "/",
      title: "Products",
      accessRole: ["admin"],
    },
    {
      id: "3",
      img: <ApartmentOutlined />,
      route: "/projects",
      title: "Projects",
      accessRole: ["admin"],
    },
    {
      id: "4",
      img: <UnorderedListOutlined />,
      route: "/blogs",
      title: "News & Blogs",
      accessRole: ["admin"],
    },
  ];

  // handler for tabs which has sub options
  const childTabs = (tab) => {
    return getItem(
      <Link>{tab?.title}</Link>,
      tab?.id,
      tab.img,
      tab?.children?.map((child) => {
        return getItem(
          <Link to={child?.route}>{child?.title}</Link>,
          child?.id
        );
      })
    );
  };

  // userTabsPermissions
  const userTabsPermissions = () => {
    const user = parseJWT(token);
    const selectedTabs = tabs?.map((tab) => {
      if (tab?.accessRole?.includes(user?.role)) {
        return tab?.children?.length > 0
          ? childTabs(tab)
          : getItem(<Link to={tab?.route}>{tab?.title}</Link>, tab.id, tab.img);
      }
      return tab?.accessRole?.includes(user?.role);
    });
    return selectedTabs;
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className="dash-sidebar"
      >
        <div className="demo-logo-vertical" />
        <div className="sidebar_logo">
          {!collapsed ? (
            <React.Fragment>
              <img src={Logo} alt="" />
            </React.Fragment>
          ) : (
            <img src={Logo} alt="" />
          )}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[activeTabHandler(location?.pathname)]}
          items={userTabsPermissions()}
          selectedKeys={[activeTabHandler(location?.pathname)]}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "20px",
              width: 64,
              height: 64,
            }}
          />
          <MenuButton />
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            overflowY: "scroll",
            background: colorBgContainer,
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Layout1;
