// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .form-edit-event-date {
  display: flex;
}
.form-edit-event-date .box1 {
  width: 50%;
}
.form-edit-event-date .box2 {
  width: 50%;
} */
`, "",{"version":3,"sources":["webpack://./src/style/form.css"],"names":[],"mappings":"AAAA;;;;;;;;GAQG","sourcesContent":["/* .form-edit-event-date {\n  display: flex;\n}\n.form-edit-event-date .box1 {\n  width: 50%;\n}\n.form-edit-event-date .box2 {\n  width: 50%;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
