import { Routes, Route, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
// FireStoppingProduct
import FireStoppingProduct from "../component/products/products";
import CreateFireStoppingProduct from "../component/products/create";
import EditFireStoppingProduct from "../component/products/edit";
// Blogs
import Blogs from "../component/blogs/blogs";
import CreateBlog from "../component/blogs/create";
import EditBlog from "../component/blogs/edit";
// Projects
import Projects from "../component/projects/projects";
import CreateProjects from "../component/projects/create";
import EditProjects from "../component/projects/edit";
// login
import Login from "../component/login/login";
// cookie
import cookie from "react-cookies";
// Layout
import Layout from "../shared/layout";

const AllRoutes = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  // useEffect for loggedIn user to check
  useEffect(() => {
    let token = cookie?.load("token");
    if (token !== undefined) {
      // navigate(-1);
      setLoggedIn(true);
    } else {
      navigate("/login");
      setLoggedIn(false);
    }
    // eslint-disable-next-line
  }, [cookie?.load("token")]);

  return (
    <React.Fragment>
      {/* login page */}
      {!loggedIn ? (
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
      ) : (
        <Layout>
          <Routes>
            {/* blogs */}
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/add-blog" element={<CreateBlog />} />
            <Route path="/edit-blog/:id" element={<EditBlog />} />
            {/* blogs */}
            <Route path="/projects" element={<Projects />} />
            <Route path="/add-project" element={<CreateProjects />} />
            <Route path="/edit-project/:id" element={<EditProjects />} />
            {/* firestopping-product */}
            <Route path="/" element={<FireStoppingProduct />} />
            <Route
              path="/add-firestopping-product"
              element={<CreateFireStoppingProduct />}
            />
            <Route
              path="/edit-firestopping-product/:id"
              element={<EditFireStoppingProduct />}
            />
          </Routes>
        </Layout>
      )}
    </React.Fragment>
  );
};
export default AllRoutes;
