import { useState, useEffect } from "react";
import {
  Pagination,
  Popconfirm,
  Spin,
  message,
  Card,
  Empty,
  Row,
  Col,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { DeleteFilled, EditOutlined } from "@ant-design/icons";
import { get, del } from "../../services";
import { tokenExpired } from "../../utils/tokenExpired";

const { Meta } = Card;

const Blogstable = () => {
  const navigate = useNavigate();
  const [adminData, setAdmindata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(false);
  const limit = 10;

  // Method for API call
  const getAllAdmins = (currentPage) => {
    setLoader(true);
    get(`/projects?page=${currentPage}&limit=${limit}`)
      .then((response) => {
        setAdmindata(response?.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        // to check token if token expired then logout user
        tokenExpired(err?.response?.data?.message, navigate);
      });
  };

  //  GET All Admins
  useEffect(() => {
    getAllAdmins(currentPage);
    // eslint-disable-next-line
  }, []);

  // confirm handler
  const confirm = (data) => {
    setSelectedRecord(data?._id);
    // API call to delete selected record
    del(`/projects/${data?._id}`)
      .then(() => {
        // to delete locally from our frontend lists
        const remaingRecords = adminData?.projects?.filter(
          (item) => item?._id !== data?._id
        );
        setAdmindata({ ...adminData, projects: remaingRecords });
        // success message
        message.success("Record deleted Successfully");
        setSelectedRecord(false);
      })
      .catch(() => {
        message.error("Error");
        setSelectedRecord(false);
      });
  };

  // Cancel
  const cancel = (e) => {
    console.log(e);
  };

  return (
    <div>
      <div className="header4">
        <h3>Projects</h3>{" "}
        <div className="events-button">
          <Link to="/add-project">
            <button className="cursor-pointer add-event">Add Project</button>
          </Link>
        </div>
      </div>
      {/* table */}
      {loader ? (
        <div className="two-color-loader">
          <div className="spinner"></div>
        </div>
      ) : adminData?.projects?.length > 0 ? (
        <div>
          <div>
            <Row gutter={24} scroll={{ x: 1023 }}>
              {adminData?.projects?.map((item, index) => (
                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6} key={index}>
                  <Card
                    cover={
                      item?.video !== "null" && item?.video !== null ? (
                        <video controls width="100%" height="300px">
                          <source src={item?.video} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img
                          alt="image"
                          src={item?.image}
                          className="card-img-height"
                        />
                      )
                    }
                    actions={[
                      <EditOutlined
                        key="edit"
                        onClick={() => navigate("/edit-project/" + item._id)}
                        className="delete-icon-admin cursor-pointer "
                      />,
                      item?._id === selectedRecord ? (
                        <Spin />
                      ) : (
                        <Popconfirm
                          title="Delete the Project"
                          description="Are you sure to delete this Project?"
                          onConfirm={() => confirm(item)}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteFilled className="delete-icon-admin cursor-pointer" />
                        </Popconfirm>
                      ),
                    ]}
                  >
                    <Meta
                      title={
                        <div className="truncate-title-one">{item?.title}</div>
                      }
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>

          <Pagination
            defaultCurrent={currentPage}
            total={adminData?.totalProjects}
            pageSize={limit}
            onChange={(e) => {
              setCurrentPage(e);
              getAllAdmins(e);
            }}
            className="pagination"
          />
        </div>
      ) : (
        <div className="no-data">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="spin-center2"
          />
        </div>
      )}
    </div>
  );
};
export default Blogstable;
