// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* user details css start here */
.user_profileimg {
  position: relative;
  margin: -80px 0px 0px 10px;
}
.user_profileimg .profile_img {
  border-radius: 100px;
  border: 5px solid #fff;
}
.user_profileimg .ant-image-mask {
  border-radius: 100px;
}
.user_profileimg h3 {
  margin: -50px 0px 0px 170px;
  font-size: 20px;
  font-weight: 600;
  color: #2b2b2b;
}
/* user details css */
.user_profile {
  position: absolute !important;
  bottom: 120px;
  left: 20px;
}
/* contact view */
.view_content {
  padding-top: 20px;
}
.view_content h2 {
  padding-bottom: 20px;
}
.view_content h4 {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.88);
}
.view_content h6 {
  font-weight: 400;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.88);
  padding-bottom: 20px;
}
.ant-card-bordered {
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/style/users.css"],"names":[],"mappings":"AAAA,gCAAgC;AAChC;EACE,kBAAkB;EAClB,0BAA0B;AAC5B;AACA;EACE,oBAAoB;EACpB,sBAAsB;AACxB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,2BAA2B;EAC3B,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;AACA,qBAAqB;AACrB;EACE,6BAA6B;EAC7B,aAAa;EACb,UAAU;AACZ;AACA,iBAAiB;AACjB;EACE,iBAAiB;AACnB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,0BAA0B;EAC1B,oBAAoB;AACtB;AACA;EACE,mBAAmB;AACrB","sourcesContent":["/* user details css start here */\n.user_profileimg {\n  position: relative;\n  margin: -80px 0px 0px 10px;\n}\n.user_profileimg .profile_img {\n  border-radius: 100px;\n  border: 5px solid #fff;\n}\n.user_profileimg .ant-image-mask {\n  border-radius: 100px;\n}\n.user_profileimg h3 {\n  margin: -50px 0px 0px 170px;\n  font-size: 20px;\n  font-weight: 600;\n  color: #2b2b2b;\n}\n/* user details css */\n.user_profile {\n  position: absolute !important;\n  bottom: 120px;\n  left: 20px;\n}\n/* contact view */\n.view_content {\n  padding-top: 20px;\n}\n.view_content h2 {\n  padding-bottom: 20px;\n}\n.view_content h4 {\n  font-weight: 600;\n  color: rgba(0, 0, 0, 0.88);\n}\n.view_content h6 {\n  font-weight: 400;\n  font-size: 15px;\n  color: rgba(0, 0, 0, 0.88);\n  padding-bottom: 20px;\n}\n.ant-card-bordered {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
