// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* events calender css */
.events {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}
.add_event_calendar p {
  padding: 2px 0px;
  font-size: 14px;
  font-weight: 400;
}

.head_events h3 {
  color: #444;
  font-size: 18px;
  font-weight: 600;
  padding: 5px 0px 20px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/style/events.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;EACE,SAAS;EACT,UAAU;EACV,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B","sourcesContent":["/* events calender css */\n.events {\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  text-align: right;\n}\n.events .ant-badge-status {\n  width: 100%;\n  overflow: hidden;\n  font-size: 12px;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n.notes-month {\n  font-size: 28px;\n  text-align: center;\n}\n.notes-month section {\n  font-size: 28px;\n}\n.add_event_calendar p {\n  padding: 2px 0px;\n  font-size: 14px;\n  font-weight: 400;\n}\n\n.head_events h3 {\n  color: #444;\n  font-size: 18px;\n  font-weight: 600;\n  padding: 5px 0px 20px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
