import axios from "axios";
import cookie from "react-cookies";

// Token
const token = cookie.load("token");

const config = (token) => {
  return {
    headers: { Authorization: token ? `Bearer ${JSON.parse(token)}` : "" },
  };
};

const multipartConfig = (token) => {
  return {
    headers: {
      Authorization: token ? `Bearer ${JSON.parse(token)}` : "",
      "Content-Type": "multipart/form-data", // Set content type for multipart/form-data
    },
  };
};

// Function to make a GET request
export const get = (url) => {
  const token = cookie.load("token");
  const requestConfig = config(token);
  return axios.get(`${process.env.REACT_APP_FRONTED_URL}${url}`, requestConfig);
};

// Function to make a POST request
export const post = async (url, data, isMultipart = false) => {
  // Use the appropriate config based on whether it's multipart or not
  const token = cookie.load("token");
  const requestConfig = isMultipart ? multipartConfig(token) : config(token);
  return await axios.post(
    `${process.env.REACT_APP_FRONTED_URL}${url}`,
    data,
    requestConfig
  );
};

// Function to make a POST request
export const uploadFile = async (url, data) => {
  // Use the appropriate config based on whether it's multipart or not
  const token = cookie.load("token");
  const requestConfig = multipartConfig(token);
  console.log("requestConfig", requestConfig);
  return await axios.post(
    `${process.env.REACT_APP_FRONTED_URL_FILE_UPLOAD}${url}`,
    data,
    requestConfig
  );
};

// Function to make a PUT request
export const put = (url, data, isMultipart = false) => {
  // Use the appropriate config based on whether it's multipart or not
  const token = cookie.load("token");
  const requestConfig = isMultipart ? multipartConfig(token) : config(token);
  return axios.put(
    `${process.env.REACT_APP_FRONTED_URL}${url}`,
    data,
    requestConfig
  );
};

// Function to make a DELETE request
export const del = (url, isMultipart = false) => {
  // Use the appropriate config based on whether it's multipart or not
  const requestConfig = isMultipart ? multipartConfig(token) : config(token);
  return axios.delete(
    `${process.env.REACT_APP_FRONTED_URL}${url}`,
    requestConfig
  );
};
