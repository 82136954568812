import { useState, useMemo } from "react";
import { Button, Form, Input, notification, Select, Upload, Tabs } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { post, uploadFile } from "../../services";

const EventForm = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  // Notification handler
  const openNotification = (message, description) => {
    notification.open({
      message,
      description,
    });
  };

  // Props for multiple image uploads
  const uploadProps = useMemo(
    () => ({
      beforeUpload: (file) => {
        const isVideo = file?.type?.split("/")[0] === "video";
        const isValidSize = file.size <= 5 * 1024 * 1024; // 5MB limit
        if (!isVideo) {
          openNotification("Error", "Please upload a video file.");
          return false;
        }
        if (!isValidSize) {
          openNotification("Error", "File size exceeds 5MB limit.");
          return false; // Prevent upload if file size exceeds 5MB
        }
        setFileList([file]);
        return false; // Prevent automatic upload
      },
      onRemove: () => setFileList([]),
    }),
    []
  );

  // Props for single image upload
  const uploadProps2 = useMemo(
    () => ({
      beforeUpload: (file) => {
        const isImage = file?.type?.split("/")[0] === "image";
        const isValidSize = file.size <= 5 * 1024 * 1024; // 5MB limit
        if (!isImage) {
          openNotification("Error", "Please upload an image file.");
          return false;
        }
        if (!isValidSize) {
          openNotification("Error", "File size exceeds 5MB limit.");
          return false; // Prevent upload if file size exceeds 5MB
        }
        setFileList2([file]);
        return false; // Prevent automatic upload
      },
      onRemove: () => setFileList2([]),
    }),
    []
  );

  // Function to upload files and get their URLs
  let uploadInProgress = false; // Track whether an upload is in progress
  const uploadFileHandler = async (file) => {
    // Prevent multiple uploads while one is in progress
    if (uploadInProgress) {
      openNotification(
        "Notification",
        "File is already uploading. Please wait."
      );
      return null; // Don't proceed with upload if another one is in progress
    }
    uploadInProgress = true; // Set flag to prevent further uploads
    try {
      const response = await uploadFile("/getSignUrlForUpload", { file: file });
      if (response?.data?.publicUrl) {
        return response.data.publicUrl;
      } else {
        openNotification("Error", "File upload failed.");
        return null;
      }
    } catch (error) {
      openNotification("Error", error?.message || "Something went wrong.");
      return null;
    } finally {
      // Reset the flag once the upload process is finished (success or failure)
      uploadInProgress = false;
    }
  };

  // Form submission handler
  const onFinish = async (values) => {
    if (isUploading) {
      openNotification(
        "Notification",
        "File is already uploading. Please wait."
      );
      return false; // Prevent form submission if upload is in progress
    }

    // Set the uploading flag to true
    setIsUploading(true);
    setLoader(true);

    if (fileList.length === 0 && fileList2.length === 0) {
      openNotification("Notification", "Please upload a file.");
      setLoader(false);
      setIsUploading(false);
      return false;
    }

    openNotification(
      "Notification",
      "Please wait, file upload may take some time."
    );

    try {
      const fileToUpload = fileList2.length > 0 ? fileList2[0] : fileList[0];

      // Upload the file using the helper function
      const uploadedFilesUrl = await uploadFileHandler(fileToUpload);
      if (!uploadedFilesUrl) {
        openNotification("Error", "File upload failed.");
        setLoader(false);
        setIsUploading(false);
        return false;
      }

      // Construct payload for the API request
      const payload = {
        title: values?.title,
        description: values?.description ? values?.description : "none",
        date: values?.date ? values?.date : "none",
        image: fileList2.length === 0 ? null : uploadedFilesUrl, // Image URL or "null"
        video: fileList.length === 0 ? null : uploadedFilesUrl, // Video URL or "null"
      };

      // Make the API call to post the data
      await post(`/projects`, payload);

      // Success handling
      setLoader(false);
      setIsUploading(false); // Reset the flag after successful upload
      navigate("/projects");
      openNotification("Success", "Record created successfully.");
    } catch (error) {
      setLoader(false);
      setIsUploading(false); // Reset the flag in case of error
      openNotification("Error", error?.message || "Something went wrong.");
    }
  };

  const onChange = (key) => {
    setActiveTab(key);
  };

  const items = [
    {
      key: "1",
      label: "Upload Image",
    },
    {
      key: "2",
      label: "Upload Video",
    },
  ];

  return (
    <>
      <div className="header">
        <h3>Add Projects</h3>
      </div>
      <div className="form_container">
        <Form layout="vertical" onFinish={onFinish}>
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          {/* Image Upload */}
          {activeTab === "1" && (
            <Form.Item
              name="image"
              label=""
              rules={[{ required: false, message: "" }]}
            >
              <Upload
                name="image"
                listType="picture"
                {...uploadProps2}
                fileList={fileList2}
              >
                <Button icon={<UploadOutlined />}>Upload image</Button>
              </Upload>
            </Form.Item>
          )}
          {activeTab === "2" && (
            <Form.Item name="images" label="">
              <Upload
                name="images"
                multiple
                {...uploadProps}
                fileList={fileList}
              >
                <Button icon={<UploadOutlined />}>Upload Video</Button>
              </Upload>
            </Form.Item>
          )}

          {/* Title Input */}
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Please enter the title." }]}
          >
            <Input />
          </Form.Item>

          {/* Title Input */}
          <Form.Item
            name="date"
            label="Date"
            rules={[{ required: false, message: "Please select your date." }]}
          >
            <Input type="date" />
          </Form.Item>

          {/* Description Input */}
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: false, message: "Please enter the description." },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          {/* Submit Buttons */}
          <Form.Item>
            <div className="form_submit">
              <Button
                className="back_button"
                onClick={() => navigate("/projects")}
              >
                Back
              </Button>
              <Button htmlType="submit" disabled={loader}>
                {loader ? "Uploading..." : "Save"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default EventForm;
