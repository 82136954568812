import { useState, useMemo, useEffect } from "react";
import { Button, Form, Input, notification, Select, Spin, Upload } from "antd";
import { useNavigate } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { uploadFile, get, put } from "../../services";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
const { Option } = Select;

const EventForm = () => {
  const logos = [
    {
      key: "FM",
      label: "FM",
      url: require("../../assets/MVL_LOGOS/FM-APPROVED.png"),
    },
    {
      key: "CCC",
      label: "CCC",
      url: require("../../assets/MVL_LOGOS/CCC.png"),
    },
    {
      key: "SGS",
      label: "SGS",
      url: require("../../assets/MVL_LOGOS/SGS.png"),
    },
    { key: "UL", label: "UL", url: require("../../assets/MVL_LOGOS/UL.png") },
    {
      key: "ETL",
      label: "ETL",
      url: require("../../assets/MVL_LOGOS/Intertek_Logo.png"),
    },
    {
      key: "ELEMENT",
      label: "ELEMENT",
      url: require("../../assets/MVL_LOGOS/element.png"),
    },
    {
      key: "UL-EU",
      label: "UL-EU",
      url: require("../../assets/MVL_LOGOS/UL-EU.png"),
    },
    {
      key: "CNS",
      label: "CNS",
      url: require("../../assets/MVL_LOGOS/CNS.png"),
    },
    {
      key: "SWRI",
      label: "SWRI",
      url: require("../../assets/MVL_LOGOS/SWRI.png"),
    },
    {
      key: "THOMAS",
      label: "THOMAS",
      url: require("../../assets/MVL_LOGOS/THOMAS BELL.png"),
    },
    {
      key: "CERTIFIRE",
      label: "CERTIFIRE",
      url: require("../../assets/MVL_LOGOS/CERTIFIRE.png"),
    },
    {
      key: "NFPA",
      label: "NFPA",
      url: require("../../assets/MVL_LOGOS/NFPA-Logo.png"),
    },
    {
      key: "CAL FIRE",
      label: "CAL FIRE",
      url: require("../../assets/MVL_LOGOS/CALFIRE.png"),
    },
    {
      key: "WIMPEY LABORATORIES",
      label: "WIMPEY LABORATORIES",
      url: require("../../assets/MVL_LOGOS/WL.png"),
    },
  ];
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [information, setInformation] = useState({});
  const [selectedLogos, setSelectedLogos] = useState([]);
  // update images states
  const [eventData, setEventData] = useState();
  const [updatedCoverPhoto, setUpdatedCoverPhoto] = useState("");

  // convert image to show
  const getBase64 = (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
      setUpdatedCoverPhoto(reader?.result);
      // return reader?.result;
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const toggleLogoSelection = (key) => {
    setSelectedLogos((prevSelected) =>
      prevSelected.includes(key)
        ? prevSelected.filter((logoKey) => logoKey !== key)
        : [...prevSelected, key]
    );
  };
  // useEffect to get content
  useEffect(() => {
    get(`/products/${id}`)
      .then((response) => {
        setSelectedLogos(response?.data?.logo);
        setInformation(response?.data);
        setEventData({
          ...response?.data,
        });
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  }, []);

  const uploadProps = useMemo(
    () => ({
      beforeUpload: (file) => {
        const isImage = file?.type?.split("/")[0] === "image";
        const isLessThan2MB = file.size / 1024 / 1024 < 2;
        if (!isImage) {
          openNotification("Error", "Upload image file");
          return false;
        }
        if (!isLessThan2MB) {
          openNotification("Error", "Image size must be less than 2 MB");
          return false;
        }
        setFileList((prevFileList) => [...prevFileList, file]);
        return false; // Prevent automatic upload
      },
      onRemove: (file) => {
        if (fileList.some((item) => item.uid === file.uid)) {
          setFileList((prevFileList) =>
            prevFileList.filter((item) => item.uid !== file.uid)
          );
          return true;
        }
        return false;
      },
    }),
    [fileList]
  );

  // handler for single files
  const uploadProps2 = useMemo(
    () => ({
      beforeUpload: (file) => {
        if (file?.type?.split("/")[0] === "image") {
          setFileList2((state) => [file]);
          getBase64(file);
          return false;
        } else {
          openNotification("Error", "Upload image file");
        }
      },
      onRemove: (file) => {
        if (fileList2.some((item) => item.uid === file.uid)) {
          setFileList2((fileList) =>
            fileList.filter((item) => item.uid !== file.uid)
          );
          return true;
        }
        return false;
      },
    }),
    [fileList2]
  );

  // notification handler
  const openNotification = (message, description) => {
    notification.open({
      message: message,
      description: description,
    });
  };

  const uploadFileHandler = async (file) => {
    try {
      const response = await uploadFile("/getSignUrlForUpload", { file: file });
      return response?.data?.publicUrl;
    } catch (error) {
      openNotification("Error", "File upload failed.");
      return false;
    }
  };

  // images handler
  const changeImagesHandler = (payload) => {
    // Adding Both
    if (payload?.images?.length > 0 && eventData?.images?.length > 0) {
      return [...payload?.images, ...eventData?.images];
    }
    // Adding old pictures only
    else if (eventData?.images?.length > 0) {
      return [...eventData?.images];
    }
    // Adding new pictures only
    else if (payload?.images?.length > 0) {
      return [...payload?.images];
    }
    // empty
    else {
      return null;
    }
  };

  // onFinish handler
  const onFinish = async (values) => {
    setLoader2(true);
    // if user update both photo and images
    let payload = {};
    let fileUploadError = false;
    // Only Cover Photo
    if (fileList2?.length > 0) {
      const files = fileList2[0];
      const uploadedFilesUrl = await uploadFileHandler(files);
      if (uploadedFilesUrl === false) {
        fileUploadError = true;
        return false;
      } else {
        // save
        payload = {
          images: uploadedFilesUrl,
        };
      }
    }
    if (!fileUploadError) {
      // updated payload
      const updatedData = {
        ...values,
        ...payload,
        category:
          values?.category === "FIRE-RESISTIVE JOINT PRODUCTS"
            ? "Fire-resistive Joint Products"
            : values?.category,
        logo: selectedLogos,
      };
      console.log("updated data", updatedData);
      put(`/products/${id}`, updatedData)
        .then(() => {
          setLoader2(false);
          navigate("/");
          openNotification("Success", "Record updated Successfully");
        })
        .catch((error) => {
          setLoader2(false);
          openNotification("Error", error?.message);
        });
    }
  };
  console.log("information", information);
  return (
    <>
      <div className="header">
        <h3>Add FireStopping Product</h3>
      </div>
      {!loader ? (
        <div className="form_container">
          <Form
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              logo: information?.logo,
              title: information?.title,
              category: information?.category,
              description: information?.description,
              keyTechnicalData: information?.keyTechnicalData,
              featuresImages: information?.featuresImages,
              featuresApplications: information?.featuresApplications,
              tested: information?.tested,
            }}
          >
            {/* Image */}
            <Form.Item
              name="images"
              label="Image"
              rules={[{ required: false, message: "Please upload image." }]}
            >
              <Upload
                name="logo"
                listType="picture"
                {...uploadProps2}
                fileList={fileList2}
              >
                <Button icon={<UploadOutlined />}>Upload Logo</Button>
              </Upload>
            </Form.Item>
            {/* Title Input */}
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: "Please enter the title." }]}
            >
              <Input />
            </Form.Item>
            {/* Category Dropdown */}
            <Form.Item
              label="Category"
              name="category"
              rules={[{ required: true, message: "Please select a category!" }]}
            >
              <Select placeholder="Select a category">
                <Option value="Fire-resistive Joint Products">
                  Fire-resistive Joint Products
                </Option>
                <Option value="MEP Penetrations Firestop Products">
                  MEP Penetrations Firestop Products
                </Option>
                <Option value="Fire Proof Coating Products">
                  Fire Proof Coating Products
                </Option>
                <Option value="General Purpose Sealants">
                  General Purpose Sealants
                </Option>
              </Select>
            </Form.Item>
            {/* Description Input */}
            <Form.Item
              name="description"
              label="Description"
              rules={[
                { required: true, message: "Please enter the description." },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>

            {/* Key Technical Data */}
            <Form.Item name="keyTechnicalData" label="Key Technical Data">
              <ReactQuill theme="snow" />
            </Form.Item>

            {/* tested */}
            <Form.Item name="tested" label="Tested">
              <ReactQuill theme="snow" />
            </Form.Item>

            {/* Features Applications */}
            <Form.Item
              name="featuresApplications"
              label="Features Applications"
            >
              <ReactQuill theme="snow" />
            </Form.Item>

            {/* Featured Images */}
            <Form.Item name="featuresImages" label="Featured Images">
              <Select
                mode="multiple"
                placeholder="Select Featured Images"
                options={[
                  { value: "CABLE TRAY", label: "CABLE TRAY" },
                  { value: "JOINT SYSTEM", label: "JOINT SYSTEM" },
                  { value: "PARTITION WALL", label: "PARTITION WALL" },
                  { value: "AC DUCT", label: "AC DUCT" },
                  { value: "METALLIC PIPE", label: "METALLIC PIPE" },
                  { value: "ELECTRICAL BOX", label: "ELECTRICAL BOX" },
                  { value: "ELECTRICAL CABLE", label: "ELECTRICAL CABLE" },
                  { value: "WOOD SURFACE", label: "WOOD SURFACE" },
                  { value: "WOOD SURFACE 2", label: "WOOD SURFACE 2" },
                  { value: "FC9000", label: "FC9000" },
                  { value: "FC9000 Wood", label: "FC9000 Wood" },
                  { value: "FABRIC", label: "FABRIC" },
                  { value: "STRUCTURAL STEEL", label: "STRUCTURAL STEEL" },
                  { value: "PERIMETER JOINT", label: "PERIMETER JOINT" },
                  { value: "HEAD OF WALL JOINT", label: "HEAD OF WALL JOINT" },
                  {
                    value: "ALUMINUM CLADDING JOINT",
                    label: "ALUMINUM CLADDING JOINT",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item label="Select Logos">
              <div className="logo-grid">
                {logos.map((logo) => (
                  <div
                    key={logo.key}
                    className={`logo-item ${
                      selectedLogos.includes(logo.key) ? "selected" : ""
                    }`}
                    onClick={() => toggleLogoSelection(logo.key)}
                  >
                    <img src={logo.url} alt={logo.label} />
                  </div>
                ))}
              </div>
            </Form.Item>
            <Form.Item>
              <div className="form_submit">
                <Button className="back_button" onClick={() => navigate("/")}>
                  Back
                </Button>
                <Button htmlType="submit">
                  {loader2 ? "wait..." : "Save"}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <div className="spin_information">
          <Spin />
        </div>
      )}
    </>
  );
};
export default EventForm;
