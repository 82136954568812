// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*  */
.cursor-pointer {
  cursor: pointer;
  font-size: 18px;
}
/* .white-color */
.mb-10 {
  margin-bottom: 10px;
}
.py-4 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.px-4 {
  padding-left: 5px;
  padding-right: 5px;
}
.py-5 {
  padding-bottom: 12px;
}
.flex {
  display: flex;
}
/* rounded */
.rounded-10 {
  border-radius: 10px;
}
.rounded-20 {
  border-radius: 20px;
}
.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
/* editor css */
.editor-height {
  min-height: 150px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 8px;
}
.border-radius-2 {
  border-radius: 2px !important;
}
/*  */
/* placeholder */
.datepicker-container ::placeholder {
  color: #000 !important;
  opacity: 1 !important; /* Firefox */
}
.datepicker-container :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000 !important;
  opacity: 1;
}
.datepicker-container ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000 !important;
}
/*  */
.csv-button {
  margin-right: 5px;
  background-color: green !important;
}
`, "",{"version":3,"sources":["webpack://./src/style/custom.css"],"names":[],"mappings":"AAAA,KAAK;AACL;EACE,eAAe;EACf,eAAe;AACjB;AACA,iBAAiB;AACjB;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,aAAa;AACf;AACA,YAAY;AACZ;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;AACA,eAAe;AACf;EACE,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,6BAA6B;AAC/B;AACA,KAAK;AACL,gBAAgB;AAChB;EACE,sBAAsB;EACtB,qBAAqB,EAAE,YAAY;AACrC;AACA;EACE,4BAA4B;EAC5B,sBAAsB;EACtB,UAAU;AACZ;AACA;EACE,mBAAmB;EACnB,sBAAsB;AACxB;AACA,KAAK;AACL;EACE,iBAAiB;EACjB,kCAAkC;AACpC","sourcesContent":["/*  */\n.cursor-pointer {\n  cursor: pointer;\n  font-size: 18px;\n}\n/* .white-color */\n.mb-10 {\n  margin-bottom: 10px;\n}\n.py-4 {\n  padding-top: 5px;\n  padding-bottom: 5px;\n}\n.px-4 {\n  padding-left: 5px;\n  padding-right: 5px;\n}\n.py-5 {\n  padding-bottom: 12px;\n}\n.flex {\n  display: flex;\n}\n/* rounded */\n.rounded-10 {\n  border-radius: 10px;\n}\n.rounded-20 {\n  border-radius: 20px;\n}\n.py-10 {\n  padding-top: 10px;\n  padding-bottom: 10px;\n}\n/* editor css */\n.editor-height {\n  min-height: 150px;\n  border: 1px solid #d9d9d9;\n  border-radius: 5px;\n  padding: 8px;\n}\n.border-radius-2 {\n  border-radius: 2px !important;\n}\n/*  */\n/* placeholder */\n.datepicker-container ::placeholder {\n  color: #000 !important;\n  opacity: 1 !important; /* Firefox */\n}\n.datepicker-container :-ms-input-placeholder {\n  /* Internet Explorer 10-11 */\n  color: #000 !important;\n  opacity: 1;\n}\n.datepicker-container ::-ms-input-placeholder {\n  /* Microsoft Edge */\n  color: #000 !important;\n}\n/*  */\n.csv-button {\n  margin-right: 5px;\n  background-color: green !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
