// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 767px) and (min-width: 568px) {
  .user_profileimg {
    margin: -55px 0px 0px 5px;
  }
  .user_profileimg .profile_img {
    width: 100px;
    height: 100px !important;
  }
  .user_profileimg .ant-image-mask {
    width: 100px;
    height: 100px !important;
  }
  .user_profileimg h3 {
    margin: -80px 0px 0px 120px;
    font-size: 17px;
  }
}
@media (max-width: 567px) and (min-width: 325px) {
  .user_profileimg {
    margin: -35px 0px 0px 5px;
  }
  .user_profileimg .profile_img {
    width: 70px;
    height: 70px !important;
  }
  .user_profileimg .ant-image-mask {
    width: 70px;
    height: 70px !important;
  }
  .user_profileimg h3 {
    margin: -105px 0px 0px 80px;
    font-size: 14px;
  }
}

@media (max-width: 775px) and (min-width: 320px) {
  .header4 {
    display: flex;
    flex-direction: column;
  }
  .events-button {
    display: flex;
    flex-direction: column;
  }
  .header4 button {
    width: 100%;
  }
  .add-event {
    margin-top: 7px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/style/media.css"],"names":[],"mappings":"AAAA;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,YAAY;IACZ,wBAAwB;EAC1B;EACA;IACE,YAAY;IACZ,wBAAwB;EAC1B;EACA;IACE,2BAA2B;IAC3B,eAAe;EACjB;AACF;AACA;EACE;IACE,yBAAyB;EAC3B;EACA;IACE,WAAW;IACX,uBAAuB;EACzB;EACA;IACE,WAAW;IACX,uBAAuB;EACzB;EACA;IACE,2BAA2B;IAC3B,eAAe;EACjB;AACF;;AAEA;EACE;IACE,aAAa;IACb,sBAAsB;EACxB;EACA;IACE,aAAa;IACb,sBAAsB;EACxB;EACA;IACE,WAAW;EACb;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":["@media (max-width: 767px) and (min-width: 568px) {\n  .user_profileimg {\n    margin: -55px 0px 0px 5px;\n  }\n  .user_profileimg .profile_img {\n    width: 100px;\n    height: 100px !important;\n  }\n  .user_profileimg .ant-image-mask {\n    width: 100px;\n    height: 100px !important;\n  }\n  .user_profileimg h3 {\n    margin: -80px 0px 0px 120px;\n    font-size: 17px;\n  }\n}\n@media (max-width: 567px) and (min-width: 325px) {\n  .user_profileimg {\n    margin: -35px 0px 0px 5px;\n  }\n  .user_profileimg .profile_img {\n    width: 70px;\n    height: 70px !important;\n  }\n  .user_profileimg .ant-image-mask {\n    width: 70px;\n    height: 70px !important;\n  }\n  .user_profileimg h3 {\n    margin: -105px 0px 0px 80px;\n    font-size: 14px;\n  }\n}\n\n@media (max-width: 775px) and (min-width: 320px) {\n  .header4 {\n    display: flex;\n    flex-direction: column;\n  }\n  .events-button {\n    display: flex;\n    flex-direction: column;\n  }\n  .header4 button {\n    width: 100%;\n  }\n  .add-event {\n    margin-top: 7px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
